import { extend } from "vee-validate";
import { required, max } from "vee-validate/dist/rules";
import { messages } from "@/config/messages";

/**
 * Rules for VeeValidate
 * @link https://vee-validate.logaretm.com/v3
 */
extend("required", {
  ...required,
  message: messages.requiredField,
  computesRequired: true,
});

extend("max", {
  ...max,
  message: messages.maxLengthField,
});
