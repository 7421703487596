import { app_routes } from "@/router/app_routes";

const SHARED_ROUTES = [
  app_routes.server_error.name,
  app_routes.not_found.name,
  app_routes.library.name,
  app_routes.login.name,
  app_routes.entry.name,
  app_routes.result_verification.name,
];

export const USER_ALLOWED_ROUTES = [
  ...SHARED_ROUTES,
  app_routes.user_home.name,
  app_routes.exam_result.name,
  app_routes.exam_processing.name,
];

export const ADMIN_ALLOWED_ROUTES = [
  ...SHARED_ROUTES,
  app_routes.admin_home.name,
  app_routes.test_edit.name,
];
