export const HTTP_METHOD_TYPES = {
  GET: "GET",
  DELETE: "DELETE",
  HEAD: "HEAD",
  OPTIONS: "OPTIONS",
  POST: "POST",
  PUT: "PUT",
  PATCH: "PATCH",
  PURGE: "PURGE",
  LINK: "LINK",
  UNLINK: "UNLINK",
} as const;
