import { MiddlewareContextType } from "@/types/MiddlewareContextType.ts";
import { GET_USER_INFO_ACTION, IS_AUTH_GETTER } from "@/constants/storeNames";
import { app_routes } from "@/router/app_routes";

export async function AuthMiddleware({
  next,
  store,
  router,
}: MiddlewareContextType): Promise<void> {
  if (!store.getters[IS_AUTH_GETTER]) {
    await store.dispatch(GET_USER_INFO_ACTION);
  }

  if (!store.getters[IS_AUTH_GETTER]) {
    await router.push({
      path: app_routes.entry.path,
    });
  }

  next();
}
