import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import { ADMIN_UPLOAD_TEST_FILE } from "@/api";
import { HttpErrorType } from "@/types/API/HttpErrorType";
import { Loading } from "@/utils/StoreDecorators";
import { UPLOAD_TEST_FILE_MODULE } from "@/constants/storeNames";
import { ErrorMessageAdapter } from "@/services/ErrorMessageHandler/ErrorMessageAdapter";

const LOADING_STATE = "loading";
const SET_VALIDATION_ERRORS = "setValidationErrors";
const errorMessageAdapter = new ErrorMessageAdapter();

@Module({ namespaced: true })
export class UploadTestFile extends VuexModule {
  public loading = false;
  public errors: string[] = [];

  @Mutation
  [SET_VALIDATION_ERRORS](errors: HttpErrorType): void {
    this.errors = Object.entries(errors)
      .map(([key, messages]) =>
        messages.map((message) => {
          return errorMessageAdapter.create(key, message);
        })
      )
      .flat(1);
  }

  @Action({ commit: SET_VALIDATION_ERRORS })
  @Loading(UPLOAD_TEST_FILE_MODULE, LOADING_STATE)
  async sendFile(file: File): Promise<HttpErrorType[]> {
    try {
      return await ADMIN_UPLOAD_TEST_FILE(file);
    } catch ({ errors }) {
      return errors;
    }
  }
}
