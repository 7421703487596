
























import { Component, Vue } from "vue-property-decorator";
import MaterialIcon from "@/components/UI/MaterialIcon.vue";
import { libraryExamplesConfig } from "@/config/libraryExamplesConfig";
import { app_routes } from "@/router/app_routes";

@Component({
  components: { MaterialIcon },
})
export default class LibraryNav extends Vue {
  public libraryPath = app_routes.library.path;
  public showLibraryNav = false;
  public linksList = libraryExamplesConfig;

  public hideLibraryNav(): void {
    this.showLibraryNav = false;
  }
}
