






import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class MaterialIcon extends Vue {
  @Prop({ type: String, required: true }) readonly name!: string;
  @Prop({ type: Boolean, default: true }) readonly outline!: boolean;
  @Prop({ type: String, default: "inherit" }) readonly color!: string;
  @Prop({ type: String, default: "xxl" }) readonly size!: number;

  get classes(): string {
    return `color-${this.color} font-size-${this.size}`;
  }
}
