import {
  Module,
  VuexModule,
  Mutation,
  Action,
  MutationAction,
} from "vuex-module-decorators";
import { Notification, Loading } from "@/utils/StoreDecorators";
import { QuestionKitsType, TaskType } from "@/types/API/QuestionKitsType";
import { ObjectOf } from "@/types/ObjectOf";
import { TestType } from "@/types/API/TestType";
import { SetStatusDataType } from "@/types/API/SetStatusDataType";
import { NOTIFY_TYPES } from "@/config/notifyTypes";
import { QuestionKitAdapter } from "@/services/QuestionKits/QuestionKitAdapter";
import { HttpErrorListType } from "@/types/API/HttpErrorType";
import { StoreActionType, StoreMutationType } from "@/types/StoreTypes";
import { NOTIFY_MESSAGE_KEY } from "@/constants/notificationMessageKeys";
import { TEST_EDITOR_MODULE } from "@/constants/storeNames";
import {
  ADMIN_GET_TEST,
  ADMIN_CLOSE_TEST,
  ADMIN_DELETE_QUESTION,
  ADMIN_SET_TEST_STATUS,
  ADMIN_UPDATE_QUESTION,
  ADMIN_ADD_QUESTION,
  ADMIN_DELETE_TEST,
} from "@/api";

const UPDATE_TEST_STATUS = "updateTestStatus";
const DELETE_QUESTION = "deleteQuestionWFromQuestionKits";
const UPDATE_QUESTION = "updateQuestionKit";
const ADD_QUESTION = "addQuestionKit";
const SET_QUESTION_ERRORS = "setQuestionErrors";
const UPDATING_QUESTION_KITS_STATE = "updatingQuestionKits";
const LOADING_STATE = "loading";

@Module({ namespaced: true })
export class TestEditorModule extends VuexModule {
  public data: QuestionKitsType | null = null;
  public updatingQuestionKits = false;
  public loading = false;
  public validateErrors: HttpErrorListType = {};

  @Mutation
  [UPDATE_TEST_STATUS]({ data }: StoreMutationType<ObjectOf<string>>): void {
    this.data = {
      ...this.data,
      ...data,
    } as QuestionKitsType;
  }

  @Mutation
  [SET_QUESTION_ERRORS](errors: HttpErrorListType): void {
    this.validateErrors = errors;
  }

  @Mutation
  [UPDATE_QUESTION](questionKit: TaskType): void {
    (this.data as Record<string, any>).questions = this.data?.questions.map(
      (question) => (questionKit.id === question.id ? questionKit : question)
    );

    this.validateErrors = {};
  }

  @Mutation
  [ADD_QUESTION](questionKit: TaskType): void {
    (this.data as ObjectOf<any>).questions.unshift(questionKit);
    this.validateErrors = {};
  }

  @Mutation
  [DELETE_QUESTION]({ data: questionId }: StoreMutationType<number>): void {
    (this.data as ObjectOf<any>).questions = this.data?.questions.filter(
      (question) => question.id !== questionId
    );
  }

  @MutationAction({ mutate: ["data"] })
  @Loading(TEST_EDITOR_MODULE, LOADING_STATE)
  async getData(id: string): Promise<{ data: QuestionKitsType | null }> {
    try {
      const data = await ADMIN_GET_TEST(id);
      return { data };
    } catch {
      return { data: null };
    }
  }

  @Action({ commit: UPDATE_TEST_STATUS })
  @Loading(TEST_EDITOR_MODULE, UPDATING_QUESTION_KITS_STATE)
  @Notification(NOTIFY_MESSAGE_KEY.TEST_STATUS_UPDATED)
  async setTestStatus(
    payload: SetStatusDataType
  ): StoreActionType<TestType | ObjectOf<string>> {
    try {
      const data = await ADMIN_SET_TEST_STATUS(payload);

      return {
        data,
        messageType: NOTIFY_TYPES.SUCCESS,
      };
    } catch ({ message }) {
      return {
        message,
        data: {},
        messageType: NOTIFY_TYPES.ERROR,
      };
    }
  }

  @Action
  @Loading(TEST_EDITOR_MODULE, UPDATING_QUESTION_KITS_STATE)
  @Notification(NOTIFY_MESSAGE_KEY.TEST_DELETED)
  async deleteTest(): StoreActionType<number> {
    try {
      const {
        data: { id },
      } = <any>this.context.state;
      await ADMIN_DELETE_TEST(id);

      return {
        messageType: NOTIFY_TYPES.SUCCESS,
        data: id,
      };
    } catch {
      return {
        messageType: NOTIFY_TYPES.ERROR,
        data: 0,
      };
    }
  }

  @Action
  @Loading(TEST_EDITOR_MODULE, UPDATING_QUESTION_KITS_STATE)
  @Notification(NOTIFY_MESSAGE_KEY.CLONED_ACTIVE_TEST)
  async cloneActiveTest(): StoreActionType<number> {
    try {
      const data = await ADMIN_CLOSE_TEST();

      return {
        messageType: NOTIFY_TYPES.SUCCESS,
        data,
      };
    } catch {
      return {
        messageType: NOTIFY_TYPES.ERROR,
        data: 0,
      };
    }
  }

  @Action({ commit: DELETE_QUESTION })
  @Loading(TEST_EDITOR_MODULE, UPDATING_QUESTION_KITS_STATE)
  @Notification(NOTIFY_MESSAGE_KEY.QUESTION_KIT_DELETED)
  async deleteQuestion(questionId: number): StoreActionType<number> {
    try {
      const {
        data: { id },
      } = <any>this.context.state;
      await ADMIN_DELETE_QUESTION(id, questionId);

      return {
        messageType: NOTIFY_TYPES.SUCCESS,
        data: questionId,
      };
    } catch {
      return {
        messageType: NOTIFY_TYPES.ERROR,
        data: 0,
      };
    }
  }

  @Action
  @Loading(TEST_EDITOR_MODULE, UPDATING_QUESTION_KITS_STATE)
  @Notification(NOTIFY_MESSAGE_KEY.QUESTION_KIT_UPDATED)
  async updateQuestion(question: TaskType): StoreActionType<undefined> {
    try {
      const {
        data: { id },
      } = <any>this.context.state;
      const data = new QuestionKitAdapter(question).getFormatData;

      const questionKit = await ADMIN_UPDATE_QUESTION(id, data);

      this.context.commit(UPDATE_QUESTION, questionKit);

      return { messageType: NOTIFY_TYPES.SUCCESS };
    } catch ({ errors }) {
      this.context.commit(SET_QUESTION_ERRORS, errors);

      return { messageType: NOTIFY_TYPES.ERROR };
    }
  }

  @Action
  @Loading(TEST_EDITOR_MODULE, UPDATING_QUESTION_KITS_STATE)
  @Notification(NOTIFY_MESSAGE_KEY.QUESTION_KIT_ADDED)
  async addQuestion(question: TaskType): StoreActionType<undefined> {
    try {
      const {
        data: { id },
      } = <any>this.context.state;
      const data = new QuestionKitAdapter(question).getFormatData;
      const questionKit = await ADMIN_ADD_QUESTION(id, data);

      this.context.commit(ADD_QUESTION, questionKit);

      return { messageType: NOTIFY_TYPES.SUCCESS };
    } catch ({ errors }) {
      this.context.commit(SET_QUESTION_ERRORS, errors);

      return { messageType: NOTIFY_TYPES.ERROR };
    }
  }
}
