import { MiddlewareContextType } from "@/types/MiddlewareContextType";
import { ObjectOf } from "@/types/ObjectOf";

export function middlewarePipeline(
  context: MiddlewareContextType,
  middleware: Array<(param: MiddlewareContextType) => void>,
  index: number
): () => void {
  const nextMiddleware = middleware[index];

  if (nextMiddleware === undefined) {
    return context.next;
  }

  return (params?: ObjectOf<string>) => {
    if (params !== undefined) {
      return context.next(params);
    }

    const nextPipeline = middlewarePipeline(context, middleware, ++index);
    nextMiddleware({ ...context, next: nextPipeline });
  };
}
