import { Module, VuexModule, Action, Mutation } from "vuex-module-decorators";
import { USER_GET_CERT_DATA, USER_GET_EXAM_DATA } from "@/api";
import { ExamDescriptionType } from "@/types/API/ExamDescriptionType";
import { ObjectOf } from "types/objectTypes/ObjectOf";
import { EXAM_DONE_STATUSES } from "@/config/examDoneStatuses";
import { Loading } from "@/utils/StoreDecorators";
import { USER_EXAM_DESCRIPTION } from "@/constants/storeNames";
import { UserCertificateDataType } from "@/types/API/UserCertificateDataType";

const SET_EXAM_DESC = "setExamDescription";
const LOADING_STATE = "loading";

@Module({ namespaced: true })
export class UserExamDescriptionModule extends VuexModule {
  public examDescription: ExamDescriptionType | ObjectOf<any> = {};
  public loading = false;

  get hasExamDoneStatus(): boolean {
    return EXAM_DONE_STATUSES.includes(this.examDescription.status);
  }

  @Mutation
  [SET_EXAM_DESC](data: ObjectOf<any>): void {
    this.examDescription = data;
  }

  @Action({ commit: SET_EXAM_DESC })
  @Loading(USER_EXAM_DESCRIPTION, LOADING_STATE)
  async getExamResult(
    id: string
  ): Promise<ExamDescriptionType | ObjectOf<any>> {
    try {
      return (await USER_GET_EXAM_DATA(id)) as ExamDescriptionType;
    } catch {
      return {};
    }
  }

  @Action
  async getCertData(
    id: string
  ): Promise<UserCertificateDataType | ObjectOf<any>> {
    try {
      return await USER_GET_CERT_DATA(id);
    } catch ({ errors }) {
      return { errors };
    }
  }
}
