import { MiddlewareContextType } from "@/types/MiddlewareContextType.ts";
import {
  GET_HOME_ROUTE_GETTER,
  IS_AUTH_GETTER,
  GET_USER_INFO_ACTION,
} from "@/constants/storeNames";

export async function GuestMiddleware({
  next,
  store,
}: MiddlewareContextType): Promise<void> {
  try {
    await store.dispatch(GET_USER_INFO_ACTION);

    const isAuth = store.getters[IS_AUTH_GETTER];

    if (isAuth) {
      next({
        path: store.getters[GET_HOME_ROUTE_GETTER],
      });
    }

    next();
  } catch {
    next();
  }
}
