import { DateFormat } from "@/services/DateFormat/DateFormat";

export class DateFormatPreview {
  // "2020-07-24 17:36:58" => "24 липня 2020, 17:36"
  public static getFullDate(dateStr: string): string {
    const parseDate = dateStr.replace(" ", "T"); // fix validate date for Safari
    const dateformat = new DateFormat(parseDate);
    return dateformat.setDay.setShortMonth.setYear.setSeparator(", ").setTime
      .getResult;
  }

  // "2020-07-24 17:36:58" => "24 лип. 2020"
  public static getDayShotMouthYear(dateStr: string): string {
    const dateformat = new DateFormat(dateStr);
    return dateformat.setDay.setShortMonth.setYear.getResult;
  }

  // "2020-07-24 17:36:58" => "24 лип. 17:36"
  public static getDayShotMouthTime(dateStr: string): string {
    const dateformat = new DateFormat(dateStr);
    return dateformat.setDay.setShortMonth.setTime.getResult;
  }
}
