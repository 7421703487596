import Vue from "vue";
import App from "@/App.vue";
import router from "@/router";
import { store } from "@/store";
import VTooltip from "v-tooltip";
import "@/utils/VeeValidateRules";
import * as Sentry from "@sentry/vue";
import Row from "@/components/UI/Row.vue";
import Notifications from "vue-notification";
import vClickOutside from "v-click-outside";
import { Integrations } from "@sentry/tracing";
import { IS_DEV } from "@/constants/buildMode";
import Container from "@/components/UI/Container.vue";

if (IS_DEV) {
  Vue.config.devtools = true;
  Vue.config.silent = true;
}

Vue.use(vClickOutside);
Vue.use(Notifications);
Vue.use(VTooltip);

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
  ],
  tracesSampleRate: process.env.VUE_APP_SENTRY_TRACES_SAMPLE_RATE,
});

Vue.component("row", Row);
Vue.component("container", Container);

export const app = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
