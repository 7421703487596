import Vue from "vue";
import VueRouter, { RouteConfig, RouteMeta } from "vue-router";
import { store } from "@/store";
import { app_routes } from "@/router/app_routes";
import { MiddlewareContextType } from "@/types/MiddlewareContextType";
import { AuthMiddleware } from "@/router/middleware/AuthMiddleware";
import { GuestMiddleware } from "@/router/middleware/GuestMiddleware";
import { AdminMiddleware } from "@/router/middleware/AdminMiddleware";
import { UserMiddleware } from "@/router/middleware/UserMiddleware";
import { middlewarePipeline } from "@/router/middlewarePipeline";
import { ExamResultMiddleware } from "@/router/middleware/ExamResultMiddleware";
import { TestSettingsMiddleware } from "@/router/middleware/TestSettingsMiddleware";
import { UserGetExamDataMiddleware } from "@/router/middleware/UserGetExamDataMiddleware";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: app_routes.entry.path,
    name: app_routes.entry.name,
    component: () => import("../views/EntryPage.vue"),
    meta: {
      title: "Офіційне тестування Уповноважених осіб | Prozorro",
      description:
        "Особистий кабінет уповноважених осіб для проходження безкоштовного тестування з метою підтвердження необхідного рівня володіння базовими знаннями у сфері публічних закупівель.",
      middleware: [GuestMiddleware],
    },
  },
  {
    path: app_routes.result_verification.path,
    name: app_routes.result_verification.name,
    component: () => import("../views/ResultVerification.vue"),
    meta: {
      title: "Перевірити результати тестування Уповноважених осіб | Prozorro",
      description:
        "Ввівши код РНОКПП Уповноваженої особи ви можете перевірити чи пройшла вона офіційне тестування УО на Прозорро",
      middleware: [GuestMiddleware],
    },
  },
  {
    path: app_routes.login.path,
    name: app_routes.login.name,
    component: () => import("../views/Login.vue"),
    meta: {
      title: "Вхід. Увійти в тестування Уповноважених осіб | Prozorro",
      description:
        "Сторінка входу в тестування Уповноважених осіб на Прозорро. Щоб перейти до тестування верифікуйтесь за допомогою електронного підпису.",
      middleware: [GuestMiddleware],
    },
  },
  {
    path: app_routes.library.path,
    name: app_routes.library.name,
    component: () => import("../views/Library.vue"),
  },
  {
    path: app_routes.user_home.path,
    name: app_routes.user_home.name,
    component: () => import("../views/user/index.vue"),
    meta: {
      middleware: [AuthMiddleware, UserMiddleware, TestSettingsMiddleware],
    },
  },
  {
    path: app_routes.exam_result.path,
    name: app_routes.exam_result.name,
    component: () => import("../views/user/ExamResult.vue"),
    meta: {
      middleware: [
        AuthMiddleware,
        UserMiddleware,
        TestSettingsMiddleware,
        ExamResultMiddleware,
      ],
    },
  },
  {
    path: app_routes.exam_processing.path,
    name: app_routes.exam_processing.name,
    component: () => import("../views/user/ExamProcessing.vue"),
    meta: {
      middleware: [AuthMiddleware, UserMiddleware, UserGetExamDataMiddleware],
    },
  },
  {
    path: app_routes.admin_home.path,
    name: app_routes.admin_home.name,
    component: () => import("../views/admin/index.vue"),
    meta: {
      middleware: [AuthMiddleware, AdminMiddleware, TestSettingsMiddleware],
    },
  },
  {
    path: app_routes.test_edit.path,
    name: app_routes.test_edit.name,
    component: () => import("../views/admin/TestEdit.vue"),
    meta: {
      middleware: [AuthMiddleware, AdminMiddleware],
    },
  },
  {
    path: app_routes.server_error.path,
    name: app_routes.server_error.name,
    component: () => import("../views/error/InternalServerError.vue"),
  },
  {
    path: app_routes.not_found.path,
    name: app_routes.not_found.name,
    component: () => import("../views/error/PageNotFound.vue"),
  },
  {
    path: app_routes.to_many_requests.path,
    name: app_routes.to_many_requests.name,
    component: () => import("../views/error/PageToManyRequests.vue"),
  },
  {
    path: app_routes.prozorro.path,
    name: app_routes.prozorro.name,
    beforeEnter() {
      location.href = process.env.VUE_APP_BASE_SITE_URL;
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

const setMetaData = (meta: RouteMeta = {}): void => {
  const DEFAULT_TITLE = "Тестування УО | Prozorro";

  document.title = meta.title || DEFAULT_TITLE;

  const description = document.head.children.namedItem(
    "description"
  ) as HTMLMetaElement;

  if (description !== null) {
    description.content = meta.description || "";
  }
};

router.beforeEach((to, from, next): void => {
  let firstIndex = 0;
  setMetaData(to.meta);

  if (!to?.meta?.middleware) {
    return next();
  }

  const context: MiddlewareContextType = { to, from, next, store, router };
  const middleware = to.meta.middleware;

  return middleware[firstIndex]({
    ...context,
    next: middlewarePipeline(context, middleware, ++firstIndex),
  });
});

export default router;
