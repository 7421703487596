import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import {
  USER_FINISH_EXAM,
  USER_GET_EXAM_DATA,
  USER_SET_EXAM_ANSWER,
  USER_START_EXAM,
  USER_GET_SIGN_INFO,
  USER_SET_SIGN_INFO,
} from "@/api";
import { ExamType } from "@/types/API/ExamType";
import { ObjectOf } from "@/types/ObjectOf";
import { AnswerValueType } from "@/types/API/AnswersTypes";
import { SignInfoType, SignResultType } from "@/types/API/SignInfoType";
import { Loading } from "@/utils/StoreDecorators";
import { USER_PROCESSING_EXAM } from "@/constants/storeNames";

const SET_EXAM_DATA = "setExamData";
const SET_FINISH_EXAM = "setFinishExam";
const SET_ANSWERED_QUESTIONS = "setAnsweredQuestions";
const LOADING_STATE = "loading";

@Module({ namespaced: true })
export class UserProcessingExamModule extends VuexModule {
  public examData: ExamType | ObjectOf<string> = {};
  public loading = false;

  @Mutation
  [SET_EXAM_DATA](examData: ExamType | ObjectOf<string>): void {
    this.examData = examData;
  }

  @Mutation
  [SET_ANSWERED_QUESTIONS](answers: AnswerValueType[] | null): void {
    if (answers === null) {
      return;
    }

    this.examData.answered_questions = answers;
  }

  @Mutation
  [SET_FINISH_EXAM]({
    status,
    time_left,
  }: {
    status: string;
    time_left: number;
  }): void {
    this.examData.status = status;
    this.examData.time_left = time_left;
  }

  @Mutation
  clearExamData(): void {
    this.examData = {};
  }

  @Action
  async startExam(): Promise<number | null> {
    try {
      return await USER_START_EXAM();
    } catch {
      return null;
    }
  }

  @Loading(USER_PROCESSING_EXAM, LOADING_STATE)
  @Action({ commit: SET_EXAM_DATA })
  async getExamData(examId: string): Promise<ExamType | ObjectOf<string>> {
    try {
      return (await USER_GET_EXAM_DATA(examId)) as ExamType;
    } catch {
      return {};
    }
  }

  @Action({ commit: SET_ANSWERED_QUESTIONS })
  async setExamAnswer(
    params: AnswerValueType
  ): Promise<AnswerValueType[] | null> {
    try {
      const {
        examData: { id },
      }: any = this.context.state;

      return await USER_SET_EXAM_ANSWER(id, params);
    } catch {
      return null;
    }
  }

  @Action({ commit: SET_FINISH_EXAM })
  async finishExam(id: number): Promise<{ status: string; time_left: number }> {
    const { status, time_left } = await USER_FINISH_EXAM(id);
    return { status, time_left };
  }

  @Action
  async getExamSignInfo(id: number): Promise<SignInfoType> {
    return await USER_GET_SIGN_INFO(id);
  }

  @Action
  async sendSign({
    id,
    sign,
  }: {
    id: number;
    sign: string;
  }): Promise<SignResultType> {
    try {
      return await USER_SET_SIGN_INFO(id, sign);
    } catch ({ errors }) {
      return { errors };
    }
  }
}
