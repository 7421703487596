import { Module, VuexModule, Action, Mutation } from "vuex-module-decorators";
import { USER_GET_EXAM_LIST } from "@/api";
import { ExamType } from "@/types/API/ExamType";
import { DateFormatPreview } from "@/services/DateFormat/DateFormatPreview";
import { Loading } from "@/utils/StoreDecorators";
import { USER_EXAMS_HISTORY } from "@/constants/storeNames";

const SET_EXAM_LIST = "setExamList";
const LOADING_STATE = "loading";

@Module({ namespaced: true })
export class UserExamsHistoryModule extends VuexModule {
  public examsList: ExamType[] = [];
  public loading = false;

  @Mutation
  [SET_EXAM_LIST](data: ExamType[]): void {
    this.examsList = data.map((el: ExamType) => ({
      ...el,
      version: `v. ${el.question_kit.version}`,
      created_at: DateFormatPreview.getFullDate(el.created_at),
      finishedAtPreview: el.finished_at
        ? DateFormatPreview.getFullDate(el.finished_at)
        : "",
    }));
  }

  @Action({ commit: SET_EXAM_LIST })
  @Loading(USER_EXAMS_HISTORY, LOADING_STATE)
  async getExamsList(): Promise<ExamType[]> {
    try {
      return await USER_GET_EXAM_LIST();
    } catch {
      return [];
    }
  }
}
