import { Module, VuexModule, Mutation } from "vuex-module-decorators";

@Module({ namespaced: true })
export class AppSettingsModule extends VuexModule {
  public hideHeader = false;

  @Mutation
  toggleVisibleHeader(hide: boolean): void {
    this.hideHeader = hide;
  }
}
