import { TestType } from "@/types/API/TestType";
import { QuestionKitBuilder } from "@/services/QuestionKits/QuestionKitBuilder";

export class QuestionKitDirector {
  private builder = new QuestionKitBuilder();

  public create(data: TestType): TestType {
    this.builder.reset();
    this.builder.setData(data);
    this.builder.setVersionNumber();
    this.builder.checkMinorVersion();
    this.builder.formatVersion();
    this.builder.formatUpdatedAt();
    this.builder.formatCreatedAt();

    return this.builder.getData();
  }
}
