import {
  Module,
  VuexModule,
  Action,
  Mutation,
  MutationAction,
} from "vuex-module-decorators";
import { isEmpty } from "lodash";
import { UserInfoType } from "@/types/API/UserInfoType";
import { STORAGE_KEY_USER_NAME } from "@/constants/localStorageKeys";
import { Loading } from "@/utils/StoreDecorators";
import { USER_MANAGER_MODULE } from "@/constants/storeNames";
import { HomeRouteBuilder } from "@/services/HomeRouteBuilder/HomeRouteBuilder";
import {
  GET_AUTH_TOKEN,
  LOGIN,
  LOGOUT,
  GET_USER_INFO,
  USER_UPDATE_EMAIL,
} from "@/api";

const SET_USER_INFO = "setUserInfo";
const LOADING_STATE = "loading";

@Module({ namespaced: true })
export class UserManagerModule extends VuexModule {
  public userInfo: UserInfoType = {};
  public userName = "";
  public loading = false;

  get isAuth(): boolean {
    return !isEmpty(this.userInfo);
  }

  get isAdmin(): boolean {
    return Boolean(this.userInfo.is_admin);
  }

  get getHomeRoute(): string {
    const builder = new HomeRouteBuilder(this);
    return builder.build();
  }

  @Mutation
  [SET_USER_INFO](userInfo: UserInfoType): void {
    if (!isEmpty(userInfo)) {
      const userName = localStorage.getItem(STORAGE_KEY_USER_NAME);
      this.userName = decodeURIComponent(window.atob(userName || ""));
    }

    this.userInfo = userInfo;
  }

  @Mutation
  setUserName(name = ""): void {
    this.userName = name;

    const base64 = window.btoa(encodeURIComponent(name));
    localStorage.setItem(STORAGE_KEY_USER_NAME, base64);
  }

  @MutationAction({ mutate: ["userInfo"] })
  async userUpdateEmail(
    email: string | null
  ): Promise<{ userInfo: UserInfoType }> {
    try {
      const userInfo = await USER_UPDATE_EMAIL(email);

      return { userInfo };
    } catch {
      return { userInfo: {} };
    }
  }

  @Action
  async getAuthToken(): Promise<string> {
    try {
      return await GET_AUTH_TOKEN();
    } catch {
      return "";
    }
  }

  @Loading(USER_MANAGER_MODULE, LOADING_STATE)
  @Action({ commit: SET_USER_INFO })
  async getUserInfo(): Promise<UserInfoType> {
    try {
      return await GET_USER_INFO();
    } catch {
      return {};
    }
  }

  @MutationAction({ mutate: ["userInfo"] })
  async login(sign: string): Promise<{ userInfo: UserInfoType }> {
    try {
      const userInfo = await LOGIN(sign);
      return { userInfo };
    } catch {
      return { userInfo: {} };
    }
  }

  @MutationAction({ mutate: ["userInfo"] })
  async logout(): Promise<{ userInfo: UserInfoType }> {
    try {
      await LOGOUT();
      return { userInfo: {} };
    } catch {
      return { userInfo: {} };
    }
  }
}
