import { MiddlewareContextType } from "@/types/MiddlewareContextType.ts";
import { IS_ADMIN_GETTER } from "@/constants/storeNames";
import { USER_ALLOWED_ROUTES } from "@/config/permissionRoutes";
import { app_routes } from "@/router/app_routes";

export function UserMiddleware({
  to,
  next,
  store,
}: MiddlewareContextType): void {
  const isAdmin = store.getters[IS_ADMIN_GETTER];

  if (USER_ALLOWED_ROUTES.includes(to.name || "")) {
    if (isAdmin) {
      next({
        path: app_routes.admin_home.path,
      });
    } else {
      next();
    }
  } else {
    next({
      path: app_routes.not_found.slug,
    });
  }
}
