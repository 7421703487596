import { ObjectOf } from "@/types/ObjectOf";
import { NOTIFY_MESSAGE_KEY } from "@/constants/notificationMessageKeys";

export const notificationMessages: ObjectOf<ObjectOf<string>> = {
  success: {
    [NOTIFY_MESSAGE_KEY.TEST_STATUS_UPDATED]: "Статус оновлено",
    [NOTIFY_MESSAGE_KEY.QUESTION_KIT_DELETED]: "Запитання видалено",
    [NOTIFY_MESSAGE_KEY.QUESTION_KIT_UPDATED]: "Запитання оновлено",
    [NOTIFY_MESSAGE_KEY.QUESTION_KIT_ADDED]: "Запитання додано",
    [NOTIFY_MESSAGE_KEY.CLONED_ACTIVE_TEST]: "Створено копію тесту",
    [NOTIFY_MESSAGE_KEY.TEST_DELETED]: "Тест видалено",
  },
  error: {
    [NOTIFY_MESSAGE_KEY.TEST_STATUS_UPDATED]:
      "Виникла помилка під час зміни статус",
    [NOTIFY_MESSAGE_KEY.QUESTION_KIT_DELETED]:
      "Виникла помилка під час видалення запитання",
    [NOTIFY_MESSAGE_KEY.QUESTION_KIT_UPDATED]:
      "Виникла помилка під час оновлення запитання",
    [NOTIFY_MESSAGE_KEY.QUESTION_KIT_ADDED]:
      "Виникла помилка під час додавання запитання",
    [NOTIFY_MESSAGE_KEY.CLONED_ACTIVE_TEST]:
      "Виникла помилка під час створення копії тесту",
    [NOTIFY_MESSAGE_KEY.TEST_DELETED]:
      "Виникла помилка під час видалення тесту",
  },
} as const;
