










import { Component, Vue, Prop } from "vue-property-decorator";
import MaterialIcon from "@/components/UI/MaterialIcon.vue";

@Component({
  components: { MaterialIcon },
})
export default class Loader extends Vue {
  @Prop({ type: String, default: "blue" }) readonly color!: string;
}
