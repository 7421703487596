import { AnswerType, TaskType } from "@/types/API/QuestionKitsType";
import { ObjectOf } from "@/types/ObjectOf";

export class QuestionKitAdapter {
  constructor(private readonly data: TaskType) {}

  get getFormatData(): ObjectOf<string | number | ObjectOf<string | number>[]> {
    const { title, id, answers } = this.data;

    return {
      id,
      title,
      answers: this.mappedAnswers(answers),
    };
  }

  private mappedAnswers(answers: AnswerType[]): ObjectOf<string | number>[] {
    return answers.map(({ title, id, is_right }: AnswerType) => ({
      title,
      id,
      is_right: +is_right,
    }));
  }
}
