import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import { ADMIN_GET_TEST_LIST } from "@/api";
import { TestType } from "@/types/API/TestType";
import { QuestionKitDirector } from "@/services/QuestionKits/QuestionKitDirector";
import { QuestionKitsCompare } from "@/services/QuestionKits/QuestionKitsCompare";
import { Loading } from "@/utils/StoreDecorators";
import { TEST_LIST_MODULE } from "@/constants/storeNames";

const questionKitDirector = new QuestionKitDirector();
const questionKitsCompare = new QuestionKitsCompare();
const SET_TESTS_LIST = "setTestsList";
const LOADING_STATE = "loading";

@Module({ namespaced: true })
export class TestListModule extends VuexModule {
  public tests: TestType[] = [];
  public loading = false;

  @Mutation
  [SET_TESTS_LIST](tests: TestType[]): void {
    this.tests = tests
      .map((el: TestType) => questionKitDirector.create(el))
      .sort((a: TestType, b: TestType) => questionKitsCompare.compare(a, b));
  }

  @Action({ commit: SET_TESTS_LIST })
  @Loading(TEST_LIST_MODULE, LOADING_STATE)
  async getData(): Promise<TestType[]> {
    try {
      return await ADMIN_GET_TEST_LIST();
    } catch {
      return [];
    }
  }
}
