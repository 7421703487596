import { MiddlewareContextType } from "@/types/MiddlewareContextType.ts";
import { IS_ADMIN_GETTER } from "@/constants/storeNames";
import { ADMIN_ALLOWED_ROUTES } from "@/config/permissionRoutes";
import { app_routes } from "@/router/app_routes";

export function AdminMiddleware({
  to,
  next,
  store,
}: MiddlewareContextType): void {
  const isAdmin = store.getters[IS_ADMIN_GETTER];

  if (isAdmin && ADMIN_ALLOWED_ROUTES.includes(to.name || "")) {
    next();
  } else {
    next({
      path: app_routes.not_found.slug,
    });
  }
}
