import {
  MONTH_LIST_SHORT,
  MONTH_LIST,
  MONTHS_LIST_IN_GENITIVE_CASE,
} from "@/constants/monthList";

const ZERO_STRING = "0";
const TIME_FIXED_LENGTH = 2;

export class DateFormat {
  private readonly date: Date;
  private separator: string;
  private formatDate = "";

  constructor(dateStr: string, separator = " ") {
    this.date = new Date(dateStr);
    this.separator = separator;
  }

  public get getResult(): string {
    return this.formatDate;
  }

  public get setDay(): DateFormat {
    this.addValue(this.date.getDate());
    return this;
  }

  public get setShortMonth(): DateFormat {
    const monthIndex: number = this.date.getMonth();
    this.addValue(MONTH_LIST_SHORT[monthIndex]);
    return this;
  }

  public get setMonth(): DateFormat {
    const monthIndex: number = this.date.getMonth();
    this.addValue(MONTH_LIST[monthIndex]);
    return this;
  }

  public get setMonthInGenitiveCase(): DateFormat {
    const monthIndex: number = this.date.getMonth();
    this.addValue(MONTHS_LIST_IN_GENITIVE_CASE[monthIndex]);
    return this;
  }

  public get setYear(): DateFormat {
    this.addValue(this.date.getFullYear());
    return this;
  }

  public get setTime(): DateFormat {
    const minutes: string = `${this.date.getMinutes()}`.padStart(
      TIME_FIXED_LENGTH,
      ZERO_STRING
    );
    const hours: string = `${this.date.getHours()}`.padStart(
      TIME_FIXED_LENGTH,
      ZERO_STRING
    );

    this.addValue(`${hours}:${minutes}`);
    return this;
  }

  public setSeparator(separator: string): DateFormat {
    this.separator = separator;
    return this;
  }

  private addValue(value: string | number): void {
    if (this.formatDate.length) {
      this.formatDate += this.separator;
    }

    this.formatDate += value;
  }
}
