import Vue from "vue";
import { store } from "@/store";
import { notificationMessages } from "@/config/notificationMessages";
import { StoreMutationType } from "@/types/StoreTypes";
import { VuexModule } from "vuex-module-decorators";
import { STORE_TOGGLE_LOADING_ACTION } from "@/constants/storeNames";

export function Notification(messageKey: string) {
  return (
    target: VuexModule,
    propertyKey: string,
    descriptor: TypedPropertyDescriptor<(...params: any[]) => Promise<any>>
  ): void => {
    const func: any = descriptor.value;

    descriptor.value = async function () {
      // eslint-disable-next-line prefer-rest-params
      const data: StoreMutationType<any> = await func.apply(this, arguments);

      Vue.notify({
        type: data.messageType,
        title:
          data.message || notificationMessages[data.messageType][messageKey],
      });

      return data;
    };
  };
}

export function Loading(moduleName: string, stateName: string) {
  return (
    target: VuexModule,
    propertyKey: string,
    descriptor: TypedPropertyDescriptor<(...params: any[]) => Promise<any>>
  ): void => {
    const func: any = descriptor.value;
    const payload = { moduleName, stateName };

    descriptor.value = async function () {
      store.commit(STORE_TOGGLE_LOADING_ACTION, { ...payload, loading: true });

      // eslint-disable-next-line prefer-rest-params
      const data = await func.apply(this, arguments);

      store.commit(STORE_TOGGLE_LOADING_ACTION, { ...payload, loading: false });

      return data;
    };
  };
}
