import Vue from "vue";
import Vuex from "vuex";
import createLogger from "vuex/dist/logger";
import { IS_DEV } from "@/constants/buildMode";
import * as MODULES from "@/constants/storeNames";
import { STORE_TOGGLE_LOADING_ACTION } from "@/constants/storeNames";
import { AppSettingsModule } from "@/store/modules/AppSettings";
import { UserManagerModule } from "@/store/modules/UserManager";
import { TestListModule } from "@/store/modules/admin/TestList";
import { TestEditorModule } from "@/store/modules/admin/TestEditor";
import { UploadTestFile } from "@/store/modules/admin/UploadTestFile";
import { UserExamsHistoryModule } from "@/store/modules/user/UserExamsHistory.ts";
import { UserProcessingExamModule } from "@/store/modules/user/UserProcessingExam";
import { UserExamDescriptionModule } from "@/store/modules/user/UserExamDescription";
import { TestSettingsModule } from "@/store/modules/TestSettings";
import { ObjectOf } from "@/types/ObjectOf";

Vue.use(Vuex);

export const store = new Vuex.Store({
  strict: IS_DEV,
  plugins: IS_DEV ? [createLogger()] : [],
  modules: {
    [MODULES.TEST_SETTINGS_MODULE]: TestSettingsModule,
    [MODULES.APP_SETTINGS_MODULE]: AppSettingsModule,
    [MODULES.USER_EXAM_DESCRIPTION]: UserExamDescriptionModule,
    [MODULES.USER_MANAGER_MODULE]: UserManagerModule,
    [MODULES.USER_EXAMS_HISTORY]: UserExamsHistoryModule,
    [MODULES.USER_PROCESSING_EXAM]: UserProcessingExamModule,
    [MODULES.TEST_LIST_MODULE]: TestListModule,
    [MODULES.UPLOAD_TEST_FILE_MODULE]: UploadTestFile,
    [MODULES.TEST_EDITOR_MODULE]: TestEditorModule,
  },
  mutations: {
    [STORE_TOGGLE_LOADING_ACTION](
      state: ObjectOf<ObjectOf<boolean>>,
      {
        moduleName,
        stateName,
        loading,
      }: {
        moduleName: string;
        stateName: string;
        loading: boolean;
      }
    ): void {
      state[moduleName][stateName] = loading;
    },
  },
});
