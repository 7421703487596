export const TEST_SETTINGS_MODULE = "test-settings";
export const TEST_LIST_MODULE = "test-list";
export const TEST_EDITOR_MODULE = "test-editor";
export const UPLOAD_TEST_FILE_MODULE = "upload-test-file";
export const USER_MANAGER_MODULE = "user-manager";
export const USER_EXAM_DESCRIPTION = "user-exam-description";
export const USER_EXAMS_HISTORY = "user-test-history";
export const USER_PROCESSING_EXAM = "user-processing-exam";
export const APP_SETTINGS_MODULE = "app-settings";

// User manager module
export const GET_USER_INFO_ACTION = `${USER_MANAGER_MODULE}/getUserInfo`;
export const GET_HOME_ROUTE_GETTER = `${USER_MANAGER_MODULE}/getHomeRoute`;
export const IS_AUTH_GETTER = `${USER_MANAGER_MODULE}/isAuth`;
export const IS_ADMIN_GETTER = `${USER_MANAGER_MODULE}/isAdmin`;

// Exam description8
export const HAS_EXAM_DONE_GETTER = `${USER_EXAM_DESCRIPTION}/hasExamDoneStatus`;
export const GET_EXAM_RESULT_ACTION = `${USER_EXAM_DESCRIPTION}/getExamResult`;

// Exam processing
export const CLEAR_EXAM_DATA_MUTATION = `${USER_PROCESSING_EXAM}/clearExamData`;
export const GET_EXAM_DATA_ACTION = `${USER_PROCESSING_EXAM}/getExamData`;

// Tests and exams settings
export const GET_TEST_SETTINGS_ACTION = `${TEST_SETTINGS_MODULE}/getTestSettings`;

// Store global mutation
export const STORE_TOGGLE_LOADING_ACTION = "toggleLoadingAction";
