import { REGEX_NUMBER } from "@/constants/regex";

export class ErrorMessageAdapter {
  public create(key: string, message: string): string {
    const isNumber = REGEX_NUMBER.test(key);

    if (isNumber) {
      return this.createFieldErrorMassage(key, message);
    }

    return message;
  }

  private createFieldErrorMassage(key: string, message: string): string {
    const [row, column] = this.getPosition(key);
    const columnText = column ? `${column}-го стовпця` : "";

    return `В ${row}-му рядку ${columnText} виникла помилка валідації: ${message}`;
  }

  private getPosition(key: string): [number, number | undefined] {
    const DELTA = 1;
    const DELIMITER = ".";

    const [rowNumber, columnNumber] = key
      .split(DELIMITER)
      .map((num) => parseInt(num) + DELTA);

    return [rowNumber, columnNumber];
  }
}
