
































import { Component, Vue, Prop } from "vue-property-decorator";
import { app_routes } from "@/router/app_routes";

@Component
export default class Header extends Vue {
  @Prop({ type: String, default: "" }) userName!: string;
  @Prop({ type: Boolean, required: true }) isAuth!: boolean;
  @Prop({ type: Boolean, required: true }) isAdmin!: boolean;
  @Prop({ type: Boolean, required: true }) hideHeader!: boolean;
  @Prop({ type: Boolean, required: true }) homeRoute!: string;
  @Prop({ type: Function, required: true }) logout!: () => Promise<void>;

  private readonly resultVerificationPagePath =
    app_routes.result_verification.path;

  get hasActiveClass(): boolean {
    return (
      (this.isAuth && !this.hideHeader) ||
      this.$route.path === this.resultVerificationPagePath
    );
  }
}
