import { app } from "@/main";
import { app_routes } from "@/router/app_routes";
import { UserManagerModule } from "@/store/modules/UserManager";

export class HomeRouteBuilder {
  private routes: { path: string; isCorrect: boolean }[] = [];
  private readonly defaultRoute = app_routes.entry.path;

  constructor(private readonly context: UserManagerModule) {
    this.routes = [
      {
        path: app_routes.prozorro.path,
        isCorrect:
          !context.isAuth && app?.$route?.path === app_routes.entry.path,
      },
      {
        path: app_routes.admin_home.path,
        isCorrect: context.isAuth && context.isAdmin,
      },
      {
        path: app_routes.user_home.path,
        isCorrect: context.isAuth && !context.isAdmin,
      },
    ];
  }

  build(): string {
    const [route] = this.routes
      .filter((item) => item.isCorrect)
      .map((item) => item.path);

    return route || this.defaultRoute;
  }
}
