import { Component, Vue } from "vue-property-decorator";

@Component
export default class BuildModeMixin extends Vue {
  public isDevelopment = false;
  public isStaging = false;
  public isProduction = false;

  created(): void {
    this.isDevelopment = process.env.VUE_APP_BUILD_ENV === "development";
    this.isStaging = process.env.VUE_APP_BUILD_ENV === "staging";
    this.isProduction = process.env.VUE_APP_BUILD_ENV === "production";
  }
}
