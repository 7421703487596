import { MiddlewareContextType } from "@/types/MiddlewareContextType.ts";
import { GET_TEST_SETTINGS_ACTION } from "@/constants/storeNames";

export async function TestSettingsMiddleware({
  next,
  store,
}: MiddlewareContextType): Promise<void> {
  await store.dispatch(GET_TEST_SETTINGS_ACTION);
  next();
}
