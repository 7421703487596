import { TestType } from "@/types/API/TestType";

const ZERO = 0;
const MAX_MINOR_VERSION = 0.999;

export class QuestionKitsCompare {
  public compare(a: TestType, b: TestType): number {
    return this.getVersion(b) - this.getVersion(a);
  }

  private getVersion({ versionNumber, isMinorVersion }: TestType): number {
    return versionNumber + (isMinorVersion ? ZERO : MAX_MINOR_VERSION);
  }
}
