export const HTTP = {
  STATUS: {
    OK: 200,
    BAD_REQUEST: 400,
    UNAUTHORIZED: 401,
    FORBIDDEN: 403,
    NOT_FOUND: 404,
    TOO_MANY_REQUESTS: 429,
    UNPROCESSABLE_ENTITY: 422,
    SERVER_ERROR: 500,
  },
} as const;

export const NOT_FOUND_HTTP_STATUSES = [
  HTTP.STATUS.NOT_FOUND,
  HTTP.STATUS.FORBIDDEN,
] as const;
