import { QuestionKitFieldValueType, TestType } from "@/types/API/TestType";
import { QuestionKitBuilderInterface } from "@/services/QuestionKits/QuestionKitBuilderInterface";
import { DateFormatPreview } from "@/services/DateFormat/DateFormatPreview";
import { ObjectOf } from "@/types/ObjectOf";

export class QuestionKitBuilder implements QuestionKitBuilderInterface {
  private data: ObjectOf<QuestionKitFieldValueType> | TestType = {};

  public reset(): void {
    this.data = {};
  }

  public setData(data: TestType): void {
    this.data = data;
  }

  public getData(): TestType {
    return this.data as TestType;
  }

  public setVersionNumber(): void {
    const { version } = this.data;
    this.data.versionNumber = parseFloat(version as string);
  }

  public checkMinorVersion(): void {
    const { version } = this.data;
    this.data.isMinorVersion = Boolean(parseFloat(version as string) % 1);
  }

  public formatVersion(): void {
    const { version } = this.data;
    this.data.version = `v. ${version}`;
  }

  public formatCreatedAt(): void {
    const { created_at } = this.data;
    this.data.created_at = DateFormatPreview.getFullDate(created_at as string);
  }

  public formatUpdatedAt(): void {
    const { updated_at } = this.data;
    this.data.updated_at = DateFormatPreview.getFullDate(updated_at as string);
  }
}
