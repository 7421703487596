// test edit
export const STATUS_ACTIVE = "active";
export const STATUS_ARCHIVED = "archived";
export const STATUS_DRAFT = "draft";

export const STATUS_ACTIVE_SLUG = "Активна";
export const STATUS_ARCHIVED_SLUG = "Архівована";
export const STATUS_DRAFT_SLUG = "Чернетка";

// exam
export const STATUS_FAIL = "fail";
export const STATUS_SUCCESS = "success";
export const STATUS_IN_PROGRESS = "in_progress";
export const STATUS_PENDING = "pending";

export const STATUS_FAIL_SLUG = "Не складено";
export const STATUS_SUCCESS_SLUG = "Складено";
export const STATUS_IN_PROGRESS_SLUG = "Не завершено";
export const STATUS_PENDING_SLUG = "В очікуванні підпису";
