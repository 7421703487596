import { MiddlewareContextType } from "@/types/MiddlewareContextType";
import {
  HAS_EXAM_DONE_GETTER,
  GET_EXAM_RESULT_ACTION,
} from "@/constants/storeNames";
import { app_routes } from "@/router/app_routes";

export async function ExamResultMiddleware({
  next,
  store,
  to,
}: MiddlewareContextType): Promise<void> {
  const { id } = to.params;

  await store.dispatch(GET_EXAM_RESULT_ACTION, id);

  const hasDoneStatus = store.getters[HAS_EXAM_DONE_GETTER];

  if (hasDoneStatus) {
    next();
  } else {
    next({
      path: app_routes.not_found.slug,
    });
  }
}
