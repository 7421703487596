import { request } from "@/api/httpHandler";
import router from "@/router";
import { TestType } from "@/types/API/TestType";
import { app_routes } from "@/router/app_routes";
import { HttpErrorListType, HttpErrorType } from "@/types/API/HttpErrorType";
import { QuestionKitsType, TaskType } from "@/types/API/QuestionKitsType";
import { SetStatusDataType } from "@/types/API/SetStatusDataType";
import { UserInfoType } from "@/types/API/UserInfoType";
import { ExamType } from "@/types/API/ExamType";
import { AnswerValueType } from "@/types/API/AnswersTypes";
import { TestSettingsType } from "@/types/API/TestSettingsType.ts";
import { ExamDescriptionType } from "@/types/API/ExamDescriptionType";
import { SignInfoType, SignResultType } from "@/types/API/SignInfoType";
import { HTTP_METHOD_TYPES } from "@/constants/httpMethodTypes";
import { ObjectOf } from "@/types/ObjectOf";
import { UserCertificateDataType } from "@/types/API/UserCertificateDataType";

// Check verification by EDRPOU
export async function CHECK_VERIFICATION(
  params: Record<string, string>
): Promise<{ certified_at: string | null }> {
  return request({
    params,
    url: "/api/users/validate",
  });
}

// Test settings requests
export async function GET_TEST_SETTINGS(): Promise<TestSettingsType> {
  return await request<TestSettingsType>({
    url: "/api/exams/settings",
  });
}

// Admin management requests
export async function ADMIN_GET_TEST_LIST(): Promise<TestType[]> {
  return await request<TestType[]>({
    url: "/api/admin/question-kits",
  });
}

export async function ADMIN_GET_TEST(id: string): Promise<QuestionKitsType> {
  return await request<QuestionKitsType>({
    url: `/api/admin/question-kits/${id}`,
  });
}

export async function ADMIN_SET_TEST_STATUS({
  id,
  status,
}: SetStatusDataType): Promise<TestType> {
  return await request<TestType>({
    url: `/api/admin/question-kits/${id}`,
    method: HTTP_METHOD_TYPES.PUT,
    params: { status },
  });
}

export async function ADMIN_UPLOAD_TEST_FILE(
  file: File
): Promise<HttpErrorType[]> {
  const formData = new FormData();
  formData.append("file", file);

  const { id } = await request<{ id: number }>({
    headers: { "Content-Type": "application/json" },
    url: "/api/admin/upload",
    method: HTTP_METHOD_TYPES.POST,
    data: formData,
  });

  await router.push(`${app_routes.admin_home.path}/${id}`);

  return [];
}

export async function ADMIN_CLOSE_TEST(): Promise<number> {
  const { id } = await request<{ id: number }>({
    url: "/api/admin/question-kits/clone",
    method: HTTP_METHOD_TYPES.POST,
  });

  return id;
}

export async function ADMIN_DELETE_QUESTION(
  questionKitsId: number,
  questionId: number
): Promise<void> {
  await request({
    url: `/api/admin/question-kits/${questionKitsId}/questions/${questionId}`,
    method: HTTP_METHOD_TYPES.DELETE,
  });
}

export async function ADMIN_UPDATE_QUESTION(
  questionKitsId: number,
  question: ObjectOf<string | number | ObjectOf<string | number>[]>
): Promise<TaskType | HttpErrorListType> {
  return await request({
    url: `/api/admin/question-kits/${questionKitsId}/questions/${question.id}`,
    method: HTTP_METHOD_TYPES.PUT,
    data: question,
  });
}

export async function ADMIN_ADD_QUESTION(
  questionKitsId: number,
  question: ObjectOf<string | number | ObjectOf<string | number>[]>
): Promise<TaskType | HttpErrorListType> {
  return await request({
    url: `/api/admin/question-kits/${questionKitsId}/questions`,
    method: HTTP_METHOD_TYPES.POST,
    data: question,
  });
}

export async function ADMIN_DELETE_TEST(questionKitsId: number): Promise<void> {
  await request({
    url: `/api/admin/question-kits/${questionKitsId}`,
    method: HTTP_METHOD_TYPES.DELETE,
  });
}

// Authentication requests
export async function GET_AUTH_TOKEN(): Promise<string> {
  const key = "auth-token";
  const data = await request<{ [key]: string }>({
    url: "/auth-token",
  });

  return data[key];
}

export async function LOGIN(sign: string): Promise<UserInfoType> {
  const { user } = await request<{ user: UserInfoType }>({
    url: "/login",
    method: HTTP_METHOD_TYPES.POST,
    data: { sign },
  });

  return user;
}

export async function LOGOUT(): Promise<any> {
  await request({
    url: "/logout",
    method: HTTP_METHOD_TYPES.POST,
  });

  await router.push({
    path: app_routes.entry.path,
  });
}

export async function GET_USER_INFO(): Promise<UserInfoType> {
  const { user } = await request<{ user: UserInfoType }>({
    url: "/api/user",
  });

  return user;
}

// User management requests
export async function USER_GET_EXAM_LIST(): Promise<ExamType[]> {
  return await request({
    url: "/api/exams",
  });
}

export async function USER_START_EXAM(): Promise<number> {
  const { id } = await request<{ id: number }>({
    url: "/api/exams",
    method: HTTP_METHOD_TYPES.POST,
  });

  return id;
}

export async function USER_GET_EXAM_DATA(
  id: string
): Promise<ExamType | ExamDescriptionType> {
  return await request({
    url: `/api/exams/${id}`,
  });
}

export async function USER_SET_EXAM_ANSWER(
  examId: number,
  params: AnswerValueType
): Promise<AnswerValueType[] | null> {
  const { answeredQuestions } = await request<{
    answeredQuestions: AnswerValueType[];
  }>({
    url: `/api/exams/${examId}`,
    method: HTTP_METHOD_TYPES.PUT,
    params,
  });

  return answeredQuestions;
}

export async function USER_FINISH_EXAM(examId: number): Promise<ExamType> {
  return await request<ExamType>({
    url: `/api/exams/${examId}/finish`,
    method: HTTP_METHOD_TYPES.PUT,
  });
}

export async function USER_UPDATE_EMAIL(
  email: string | null
): Promise<UserInfoType> {
  const { user } = await request<{ user: UserInfoType }>({
    url: "/api/user",
    method: HTTP_METHOD_TYPES.POST,
    data: { email },
  });

  return user;
}

export async function USER_GET_SIGN_INFO(
  examId: number
): Promise<SignInfoType> {
  return await request<SignInfoType>({
    url: `/api/exams/${examId}/sign-info`,
  });
}

export async function USER_SET_SIGN_INFO(
  examId: number,
  sign: string
): Promise<SignResultType> {
  return await request<SignResultType>({
    url: `/api/exams/${examId}/sign`,
    method: HTTP_METHOD_TYPES.POST,
    data: { sign },
  });
}

export async function USER_GET_CERT_DATA(
  examId: string
): Promise<UserCertificateDataType> {
  return await request<UserCertificateDataType>({
    url: `api/exams/${examId}/certificate-info`,
  });
}
