import { isEmpty } from "lodash";
import { MiddlewareContextType } from "@/types/MiddlewareContextType.ts";
import { app_routes } from "@/router/app_routes";
import { EXAM_DONE_STATUSES } from "@/config/examDoneStatuses";
import {
  CLEAR_EXAM_DATA_MUTATION,
  GET_EXAM_DATA_ACTION,
  USER_PROCESSING_EXAM,
} from "@/constants/storeNames";

export async function UserGetExamDataMiddleware({
  to,
  next,
  store,
}: MiddlewareContextType): Promise<void> {
  const { id } = to.params;
  const { examData } = store.state[USER_PROCESSING_EXAM];

  if (isEmpty(examData)) {
    await store.dispatch(GET_EXAM_DATA_ACTION, id);
  }

  const { status } = store.state[USER_PROCESSING_EXAM].examData;

  if (EXAM_DONE_STATUSES.includes(status)) {
    store.commit(CLEAR_EXAM_DATA_MUTATION);

    next({
      path: app_routes.not_found.slug,
    });
  } else {
    next();
  }
}
