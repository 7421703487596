





























import Component, { mixins } from "vue-class-component";
import { namespace } from "vuex-class";
import Header from "@/components/UI/Header.vue";
import LibraryNav from "@/components/library/LibraryNav.vue";
import Loader from "@/components/UI/Loader.vue";
import BuildModeMixin from "@/mixins/BuildModeMixin";
import {
  APP_SETTINGS_MODULE,
  USER_MANAGER_MODULE,
} from "@/constants/storeNames";

const userManager = namespace(USER_MANAGER_MODULE);
const appSettings = namespace(APP_SETTINGS_MODULE);

@Component({
  components: {
    Loader,
    LibraryNav,
    "base-header": Header,
  },
})
export default class App extends mixins(BuildModeMixin) {
  @userManager.State userName!: string;
  @userManager.Getter isAuth!: boolean;
  @userManager.Getter isAdmin!: boolean;
  @userManager.State("loading") loadingUserInfo!: boolean;
  @userManager.Getter getHomeRoute!: string;
  @appSettings.State hideHeader!: boolean;

  @userManager.Action logout!: () => Promise<void>;
}
