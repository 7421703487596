import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import router from "@/router";
import { HTTP, NOT_FOUND_HTTP_STATUSES } from "@/constants/http";
import { app_routes } from "@/router/app_routes";
import { HTTP_METHOD_TYPES } from "@/constants/httpMethodTypes";

export const http = axios.create({
  withCredentials: true,
  baseURL: process.env.VUE_APP_API_URL,
});

export async function request<T>(options: AxiosRequestConfig): Promise<T> {
  const onSuccess = ({ data }: AxiosResponse<T>): T => data;
  const onError = async ({ response }: AxiosError): Promise<any> => {
    const { status, data }: any = response;

    if (response === undefined || status >= HTTP.STATUS.SERVER_ERROR) {
      return await router.push({
        name: app_routes.server_error.name,
      });
    } else if (HTTP.STATUS.TOO_MANY_REQUESTS === status) {
      return await router.push({
        path: app_routes.to_many_requests.path,
      });
    } else if (NOT_FOUND_HTTP_STATUSES.includes(status)) {
      return await router.push({
        path: app_routes.not_found.slug,
      });
    } else if (HTTP.STATUS.UNPROCESSABLE_ENTITY === status) {
      return Promise.reject(data);
    }

    return Promise.reject(response);
  };

  return await http({
    method: HTTP_METHOD_TYPES.GET,
    ...options,
  })
    .then(onSuccess)
    .catch(onError);
}
