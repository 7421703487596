import { RoutesType } from "@/types/RoutesType";

export const app_routes: RoutesType = {
  // library
  library: {
    name: "library",
    path: "/library",
  },
  // entry page
  entry: {
    name: "entry_page",
    path: "/entry",
  },
  // result verification
  result_verification: {
    name: "result_verification",
    path: "/result-verification",
  },
  // login
  login: {
    name: "login",
    path: "/login",
  },
  // user
  user_home: {
    name: "user_home",
    path: "/",
  },
  exam_result: {
    name: "exam_result",
    path: "/exam-result/:id",
    slug: "/exam-result",
  },
  exam_processing: {
    name: "exam_processing",
    path: "/exam-processing/:id",
    slug: "/exam-processing",
  },
  // admin
  admin_home: {
    name: "admin_home",
    path: "/admin",
  },
  test_edit: {
    name: "test_edit",
    path: "/admin/:id",
    slug: "/admin",
  },
  // error pages
  server_error: {
    name: "server_error",
    path: "/server-error",
  },
  not_found: {
    name: "not_found",
    path: "*",
    slug: "/not-found",
  },
  to_many_requests: {
    name: "to_many_requests",
    path: "/to-many-requests",
  },
  // outside links
  prozorro: {
    name: "prozorro",
    path: "/prozorro",
  },
};
