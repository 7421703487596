import { LibraryConfigType } from "@/types/LibraryConfigType";

export const libraryExamplesConfig: LibraryConfigType[] = [
  {
    title: "Typography",
    componentName: "typography-example",
    attrId: "typography-example",
  },
  {
    title: "Buttons list",
    componentName: "buttons-list-example",
    attrId: "buttons-list",
  },
  {
    title: "Icons list",
    componentName: "icons-list-example",
    attrId: "icons-list",
  },
  {
    title: "Form elements",
    componentName: "form-elements-example",
    attrId: "form-elements",
  },
  {
    title: "Modal window",
    componentName: "modal-windows-example",
    attrId: "modal-windows",
  },
  {
    title: "Table",
    componentName: "example-table",
    attrId: "example-table",
  },
  {
    title: "Task editor",
    componentName: "task-editor-example",
    attrId: "task-editor-example",
  },
  {
    title: "Notifications",
    componentName: "notifications-example",
    attrId: "notifications",
  },
  {
    title: "Switch content",
    componentName: "switch-content-example",
    attrId: "switch-content-example",
  },
  {
    title: "Pagination",
    componentName: "pagination-example",
    attrId: "pagination-example",
  },
  {
    title: "Tooltip",
    componentName: "tooltip-example",
    attrId: "tooltip-example",
  },
  {
    title: "Answer status bar",
    componentName: "answer-status-bar-example",
    attrId: "answer-status-bar",
  },
  {
    title: "Timer",
    componentName: "timer-example",
    attrId: "timer-example",
  },
];
