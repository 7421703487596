import { Module, VuexModule, MutationAction } from "vuex-module-decorators";
import { isEmpty } from "lodash";
import { GET_TEST_SETTINGS } from "@/api";
import { TestSettingsType } from "@/types/API/TestSettingsType.ts";

@Module({ namespaced: true })
export class TestSettingsModule extends VuexModule {
  public testSettings: TestSettingsType = {};

  get hasActiveVersion(): boolean {
    return Boolean(this.testSettings.active_version);
  }

  get hasSettings(): boolean {
    return !isEmpty(this.testSettings);
  }

  @MutationAction({ mutate: ["testSettings"] })
  async getTestSettings(): Promise<{ testSettings: TestSettingsType }> {
    try {
      const testSettings = await GET_TEST_SETTINGS();

      return { testSettings };
    } catch {
      return { testSettings: {} };
    }
  }
}
